import { render, staticRenderFns } from "./online-guitar-lessons.vue?vue&type=template&id=4944fea0"
import script from "./online-guitar-lessons.vue?vue&type=script&setup=true&lang=ts"
export * from "./online-guitar-lessons.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./online-guitar-lessons.vue?vue&type=style&index=0&id=4944fea0&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AuthSso: require('/usr/src/app/components/auth/sso.vue').default,FormPromoWithEmail: require('/usr/src/app/components/form/promo-with-email.vue').default,ThumbnailIconCaption: require('/usr/src/app/components/thumbnail/icon-caption.vue').default,GridThreeRows: require('/usr/src/app/components/grid/three-rows.vue').default,ThumbnailImgCaptionCurved: require('/usr/src/app/components/thumbnail/img-caption-curved.vue').default,BannerAd: require('/usr/src/app/components/banner/ad.vue').default,GeneralInfoTestimonials: require('/usr/src/app/components/general-info/testimonials.vue').default,GeneralInfoPlans: require('/usr/src/app/components/general-info/plans.vue').default,BannerJoinFree: require('/usr/src/app/components/banner/join-free.vue').default,TfFooterSummary: require('/usr/src/app/components/tf/footer/summary.vue').default,AuthJoinOrLoginForm: require('/usr/src/app/components/auth/join-or-login-form.vue').default,ModalBasic: require('/usr/src/app/components/modal/basic.vue').default,ModalYoutubeVideo: require('/usr/src/app/components/modal/youtube-video.vue').default})
