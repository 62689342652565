import { render, staticRenderFns } from "./userbar.vue?vue&type=template&id=0858b6f6&scoped=true"
import script from "./userbar.vue?vue&type=script&lang=js"
export * from "./userbar.vue?vue&type=script&lang=js"
import style0 from "./userbar.vue?vue&type=style&index=0&id=0858b6f6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0858b6f6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TfImg: require('/usr/src/app/components/tf/img.vue').default})
