
	export default {
		data(){
			return {
				items: [],
				configData: {},
				backLink: false
			}
		},
		methods: {
			addItem(label, url){
				this.items.push({label: label, url: url})
			}
		},
		async created() {
			this.configData = await this.$player.getConfig()
			if(process.client && this.configData && (this.configData.whitelabel)){
				this.backLink = `/partnerships/directory`
				if(location.href.includes('partner-nonce'))
					this.backLink += `?partner-nonce=${location.href.substring(location.href.indexOf('=') + 1)}`
			}
			if(!this.configData.whitelabel){
				const data = await this.$player.getProduct()
				this.addItem('Home', '/')
				this.addItem('Courses', '/courses')
				try {
					this.addItem(data.product.name, `/c${data.product.id}`)
					this.addItem(`${data.lesson.section_name}: ${data.lesson.subname}`, `/c${data.lesson.id}`)
				} catch(err){ }
			}
		}
	}
