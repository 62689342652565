
	export default {
		created(){
			this.setupItems('past')
		},
		methods: {
			setupItems(field){
				if(this.$parent.educator.live[field] && (this.$parent.educator.live[field].length)){
					for(let i = 0; i < this.$parent.educator.live[field].length; i++){
						let time = this.$parent.educator.live[field][i].time
						if(time.indexOf(' at ') > -1) time = time.replace(' at ', ' ')
						if(time.indexOf('th ') > -1) time = time.replace('th ', ' ')
						if(time.indexOf('rd ') > -1) time = time.replace('rd ', ' ')
						if(time.indexOf('nd ') > -1) time = time.replace('nd ', ' ')
						if(time.indexOf('st ') > -1) time = time.replace('st ', ' ')
						if(time.indexOf('th, ') > -1) time = time.replace('th, ', ' ')
						if(time.indexOf('rd, ') > -1) time = time.replace('rd, ', ' ')
						if(time.indexOf('nd, ') > -1) time = time.replace('nd, ', ' ')
						if(time.indexOf('st, ') > -1) time = time.replace('st, ', ' ')
						const checks = ['3:00', '4:00']
						for(let j = 0; j < checks.length; j++) if(time.includes(checks[j])) time = time.substring(0, time.indexOf(checks[j]))
						time = new Date(time)
						time = time.toLocaleDateString('en-US', {year: 'numeric', month: 'long', day: 'numeric'})
						event.time = time
						this.$parent.educator.live[field][i].time = time
					}
				}
			}
		}
	}
