
	import axios from "axios"
	export default {
		data() {
			return {
				darkMode: false,
				tabsyncUrl: false,
				recording: false,
				playingVideo: false,
				poster: true,
				posterHover: false,
				active: false,
				restricted: false,
				height: 0,
				checkingSSReportTime: false,
				elapsedTimeForReporting: 0,
				recordedElapsedVideoView: false,
				seekingSoundsliceForward: false,
				seekingSoundsliceBackward: false,
				seekingSoundsliceUD: false,
				ready: false,
				youtubeUrl: false,
				hardwareCtrl: false,
				hardwareAlt: false,
				hardwareShift: false,
				hardwareCurrentRate: 1,
				hardwareRewindingFiveSec: false,
				hardwareFastForwarding: false,
				lastReport: 0,
				hardwareSpeedingUp: false,
				hardwareSlowingDown: false,
				ssEventsData: false,
				hardwareRewinding: false,
				ssContent: false
			}
		},
		methods: {
			setup(){
				this.restricted = false
				if(this.$store.state.player.lesson.access) {
					this.$store.state.player.lesson.soundslice && (this.$store.state.player.lesson.soundslice.syncpoints) ? this.setupSoundslice() : this.setupHls()
				} else this.restricted = true
			},
			setupSoundslice() {
				this.height = 60 + 720
				this.tabsyncUrl = `https://truefire.com/inc/soundsliceappshell/072020/?courseid=${this.$store.state.player.product.id}&lessonid=${this.$store.state.player.lesson.id}&force_top_video=1&top_controls=1&
						branding=2&show_notation=1&waveform=0&api=1&zoom=-9&narrow_video_height=1000&show_title=0` //&uispace=39
				this.setupSSEvents()
				this.setupKeyEvents()
			},
			async setupHls() {
				this.height = 60
				let hlsUrl
				if (this.$store.state.player.lesson.assets) for (let i = 0; i < this.$store.state.player.lesson.assets.length; i++) {
					if (this.$store.state.player.lesson.assets[i].type) {
						if (this.$store.state.player.lesson.assets[i].type === 'hls') hlsUrl = this.$store.state.player.lesson.assets[i].url
					} else {
						for (const id in this.$store.state.player.lesson.assets[i])
							if (this.$store.state.player.lesson.assets[i][id].type === 'hls') hlsUrl = this.$store.state.player.lesson.assets[i][id].url
					}
				}
				if (!hlsUrl) {
					if (this.$store.state.player.lesson.assets) for (const key in this.$store.state.player.lesson.assets) {
						if (this.$store.state.player.lesson.assets[key].type) {
							if (this.$store.state.player.lesson.assets[key].type === 'hls') hlsUrl = this.$store.state.player.lesson.assets[key].url
						}
					}
				}
				if (hlsUrl) {
					hlsUrl += (hlsUrl.includes('?') ? `&` : '?') + `url_only=1`
					const req = await fetch(hlsUrl)
					const data = await req.json()
					if(data && data.result){
						if(data.result.includes('youtube')){
							this.youtubeUrl = 'https://www.youtube.com/embed/' + 
								(data.result.substring(data.result.indexOf('embed/') + 6, data.result.indexOf('/playlist.m3u8'))) + 
								'?enablejsapi=1&amp;origin=https%3A%2F%2Ftruefire.com&amp;modestbranding=1&amp;rel=0&amp;showinfo=0'
							this.youtubeUrl = this.youtubeUrl.replace(/ /g, '')
						} else {
							hlsUrl = data.result
							this.tabsyncUrl = `/inc/soundsliceappshell/072020/?api=1&show_title=0&branding=2&show_notation=0&waveform=0&videourl=${encodeURIComponent(hlsUrl)}`
							this.setupSSEvents()
							this.setupKeyEvents()
						}
					}
				}
			},
			setupSSEvents() {
				if (this.ssEventsData) window.removeEventListener('message', this.ssEventsData)
				window.onmessage = false
				this.$nextTick(() => {
					this.ssContent = document.getElementById('tabsyncFrame').contentWindow
					this.ssEventsData = this.ssEvents
					this.$nextTick(() => {
						window.addEventListener('message', this.ssEventsData)
					})
				})
			},
			recordVideoView() {
				this.recordedElapsedVideoView = true
				const postObj = {
					course: {
						marklessonview: {
							seconds: 30,
							sid: this.$store.state.player.lesson.id,
							cid: this.$store.state.player.product.id,
							soundslice: 1,
							origin: 'web'
						}
					}
				}
				this.$nexus(postObj)
				// axios.post(this.$root.nexusUrl(), postObj)
			},
			postSSMessage(message) {
					if(this.ssContent && message){
						this.ssContent.postMessage(message)
					}
			},
			triggerReportSoundslice() {
				if (this.ssContent) {
					this.checkingSSReportTime = true
					this.postSSMessage('{"method": "getCurrentTime"}')
				}
			},
			async addRecentlyWatched(){
				// const postObj = {
				// 	courses: {
				// 		add_recently_watched: {
				// 			id: this.$store.state.player.lesson.id
				// 		}
				// 	}
				// }
				// const data = await axios.post(this.$root.nexusUrl(), postObj)
			},
			checkReportSoundslice(data) {
				if(!this.recordedElapsedVideoView){
					this.checkingSSReportTime = false
					if(this.playingVideo && data >= 3) this.elapsedTimeForReporting += 3
					this.elapsedTimeForReporting >= 30 ? this.recordVideoView() : setTimeout(this.triggerReportSoundslice, 3000)
				}
			},
			doAutoplay() {
				// this.$root.playNextLesson()
			},
			ssEvents(e) {
				if (e.data && (String(e.data).includes('"method":'))) {
					const data = JSON.parse(e.data)
					switch (data.method) {
						// load up audio
						case 'ssAudioLoaded':
							const thisObj = this
							this.ready = true
							setTimeout(function () {
								// if (thisObj.$root.started && thisObj.$root.autoplay) {
									thisObj.postSSMessage('{"method": "play"}')
								// } else {
									// thisObj.$root.started = true
								// }
								// if (Cookies.get('set_volume')) thisObj.postSSMessage('{"method": "setVolume", "arg": ' + Cookies.get('set_volume') + '}')
							}, 1000);
							break;
						// load up player
						case 'ssPlayerReady':
							this.triggerReportSoundslice()
							// if(tf.member) this.addRecentlyWatched()
							break;
						// current time
						case 'ssCurrentTime':
							const switchTime = data.arg
							if(this.hardwareRewindingFiveSec)
								this.postSSMessage('{"method": "seek", "arg": ' + (switchTime - 5) + '}')
							else if(this.hardwareFastForwarding)
								this.postSSMessage('{"method": "seek", "arg": ' + (switchTime + 1) + '}')
							else if(this.hardwareRewinding)
								this.postSSMessage('{"method": "seek", "arg": ' + (switchTime - 1) + '}')
							else if (this.checkingSSReportTime) this.checkReportSoundslice(data.arg)
							else {
								var ssTime = this.seekingSoundsliceUD ? 5 : 10
								this.seekingSoundsliceUD = false
								if (this.seekingSoundsliceForward) {
									this.seekingSoundsliceForward = false
									this.postSSMessage('{"method": "seek", "arg": ' + (switchTime + ssTime) + '}')
								} else if (this.seekingSoundsliceBackward) {
									this.seekingSoundsliceBackward = false
									this.postSSMessage('{"method": "seek", "arg": ' + (switchTime - ssTime) + '}')
								}
							}
							break;
						// start playback
						case 'ssPlay':
							this.poster = false
							this.tracking = true
							this.playingVideo = true
							// this.$root.pauseJamTrack()
							this.doProgressTracking()
							break;
						// pause playback
						case 'ssPause':
							this.tracking = false
							this.playingVideo = false
							break;
						// track end
						case 'ssAudioEnd':
							this.tracking = false
							this.playingVideo = false
							// if (this.$root.autoplay) this.doAutoplay()
							break;
						// volume
						case 'ssVolumeChange':
							this.postSSMessage('{"method": "getVolume"}')
							break;
						case 'ssVolume':
							// if (data.arg) Cookies.set('set_volume', String(data.arg), { expires: 365, path: '/' })
							break;
					}
				}
			},
			playVideo() {
				this.postSSMessage('{"method": "play"}', '*')
				if (this.poster) this.poster = false
			},
			pauseVideo() {
				this.postSSMessage('{"method": "pause"}', '*');
			},
			doProgressTracking() {
					// if (this.tracking) {
					// 	const progressObj = {
					// 		lesson: this.$store.state.player.lesson.id,
					// 		seconds: [0, 1, 2, 3, 4]
					// 	}
					// 	// localStorage.setItem(`lesson_${progressObj.lesson}`, JSON.stringify(progressObj))
					// 	setTimeout(this.doProgressTracking, 250)
					// }
			},
			setupKeyEvents() {
				window.removeEventListener('keydown', this.keyEvents)
				window.addEventListener('keydown', this.keyEvents)
				window.removeEventListener('keyup', this.keyUnEvents)
				window.addEventListener('keyup', this.keyUnEvents)
			},
			keyEvents(e){
				if (e.target.tagName.toLowerCase() != 'input' && e.target.tagName.toLowerCase() != 'textarea') {
					switch (e.code.toLowerCase()) {
						case 'space':
							e.preventDefault()
							// if (this.ready) this.playingVideo ? this.pauseVideo() : this.playVideo()
							break;
						case 'arrowleft':
							e.preventDefault()
							this.seekingSoundsliceBackward = true
							this.postSSMessage('{"method": "getCurrentTime"}')
							break;
						case 'arrowright':
							e.preventDefault()
							this.seekingSoundsliceForward = true
							this.postSSMessage('{"method": "getCurrentTime"}')
							break;
						case 'controlleft': this.doHardwareEvent(e.code.toLowerCase()); break;
						case 'controlright': this.doHardwareEvent(e.code.toLowerCase()); break;
						case 'altleft': this.doHardwareEvent(e.code.toLowerCase()); break;
						case 'altright': this.doHardwareEvent(e.code.toLowerCase()); break;
						case 'shiftleft': this.doHardwareEvent(e.code.toLowerCase()); break;
						case 'shiftright': this.doHardwareEvent(e.code.toLowerCase()); break;
						case 'keye': this.doHardwareEvent(e.code.toLowerCase()); break;
						case 'keyl': this.doHardwareEvent(e.code.toLowerCase()); break;
						case 'keyg': this.doHardwareEvent(e.code.toLowerCase()); break;
						case 'keyo': this.doHardwareEvent(e.code.toLowerCase()); break;
						case 'keyr': this.doHardwareEvent(e.code.toLowerCase()); break;
						case 'keyp': this.doHardwareEvent(e.code.toLowerCase()); break;
						case 'keyg': this.doHardwareEvent(e.code.toLowerCase()); break;
						case 'keyy': this.doHardwareEvent(e.code.toLowerCase()); break;
					}
				}
			},
			keyUnEvents(e){
				if (e.target.tagName.toLowerCase() != 'input' && e.target.tagName.toLowerCase() != 'textarea') {
					switch(e.code.toLowerCase()) {
						case 'controlleft': this.hardwareCtrl = false; break;
						case 'controlright': this.hardwareCtrl = false; break;
						case 'altleft': this.hardwareAlt = false; break;
						case 'altright': this.hardwareAlt = false; break;
						case 'shiftleft': this.hardwareShift = false; break;
						case 'shiftright': this.hardwareShift = false; break;
					}
				}
			},
			doHardwareEvent(input){
				if(input.includes('control')) this.hardwareCtrl = true
				else if(input.includes('alt')) this.hardwareAlt = true
				else if(input.includes('shift')) this.hardwareShift = true

				if(this.hardwareCtrl && this.hardwareAlt && this.hardwareShift){
					switch(input){
						case 'keye': this.hardwarePlayPause(); break;
						case 'keyl': this.hardwarePlayPause(); break;
						case 'keyg': this.hardwareRewindFiveSec(); break;
						case 'keyo': this.hardwareFastForward(); break;
						case 'keyr': this.hardwareSpeedUp(); break;
						case 'keyp': this.hardwareSlowDown(); break;
						case 'keyg': this.hardwareRewind(); break;
						case 'keyy': this.hardwareSpecialFunction(); break;
					}
				}
			},
			hardwarePlayPause(){
				// this.playingVideo ? this.pauseVideo() : this.playVideo()
			},
			hardwareRewindFiveSec(){
				this.hardwareRewindingFiveSec = true
				this.postSSMessage('{"method": "getCurrentTime"}')
			},
			hardwareFastForward(){
				this.hardwareFastForwarding = true
				this.postSSMessage('{"method": "getCurrentTime"}')
			},
			hardwareSpeedUp(){
				hardwareCurrentRate += 0.1
				if(hardwareCurrentRate >= 1.5) hardwareCurrentRate = 1.5
				this.postSSMessage('{"method": "setSpeed", "arg": ' + hardwareCurrentRate + '}')
			},
			hardwareSlowDown(){
				hardwareCurrentRate -= 0.1
				if(hardwareCurrentRate <= 0.2) hardwareCurrentRate = 0.25
				this.postSSMessage('{"method": "setSpeed", "arg": ' + hardwareCurrentRate + '}')
			},
			hardwareRewind(){
				this.hardwareRewinding = true
				this.postSSMessage('{"method": "getCurrentTime"}')
			},
			hardwareSpecialFunction(){
				console.log('Special Function')
			}
		},
		created(){
			this.setup()
		},
		watch: {
			lesson() {
				// this.active = false
				// this.$nextTick(() => {
					this.setup()
					// this.active = true
				// })
			}
		},
		computed: {
			lesson(){
				return this.$store.state.player.lesson
			}
		}
	}
