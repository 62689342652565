import { render, staticRenderFns } from "./browse.vue?vue&type=template&id=940871d8"
import script from "./browse.vue?vue&type=script&lang=js"
export * from "./browse.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BannerImg: require('/usr/src/app/components/banner/img.vue').default,ThumbnailImgCaptionSharp: require('/usr/src/app/components/thumbnail/img-caption-sharp.vue').default,GridDynamic: require('/usr/src/app/components/grid/dynamic.vue').default,GridCategories: require('/usr/src/app/components/grid/categories.vue').default,ThumbnailImgCaptionCircle: require('/usr/src/app/components/thumbnail/img-caption-circle.vue').default,GeneralInfoDownloadTheApp: require('/usr/src/app/components/general-info/download-the-app.vue').default,TfImg: require('/usr/src/app/components/tf/img.vue').default})
